import { useLocation, useNavigate } from 'react-router-dom'
import { HttpStatus } from '@packages/constants'
import axios, { AxiosResponse } from 'axios'
import client from 'services/client'
import AffiliateProfile from 'services/types/AffiliateProfile'
import { UpdateCurrentUserParams } from 'services/types/Auth'
import User from 'services/types/User'
import UserProfile from 'services/types/UserProfile'
import { SWRFetcherKey } from 'shared/types'
import { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation from 'swr/mutation'

export type LoginRequestParams = {
  email: User['email'] | User['username']
  password: User['password']
}

export type LoginResponse = {
  type: 'bearer'
  token: string
  requiresTwoFactorAuthentication: boolean
}

export const login = async (payload: LoginRequestParams) => {
  return (await client.post<LoginResponse>(`/login`, payload)).data
}

export const verifyToken = async (code: string) => {
  return (await client.post<LoginResponse>(`/verify-token`, { code })).data
}

export type RegisterRequestParams = Required<
  Pick<User, 'email' | 'name' | 'password' | 'username'> & {
    affiliateProfile: Pick<
      AffiliateProfile,
      | 'mainProduct'
      | 'trafficSourceId'
      | 'businessModel'
      | 'expectedVolumes'
      | 'website'
      | 'countryId'
      | 'city'
      | 'address'
      | 'zipCode'
      | 'taxNumber'
      | 'companyName'
    > & {
      agreedToTerms: string | null
      receivePromotionalMaterials?: string | null
      receiveUpdates?: string | null
    }
    repeatPassword: User['password']
  }
> & {
  userProfile: Pick<UserProfile, 'phoneNumber' | 'skype'>
}

export const register = async (payload: RegisterRequestParams) => {
  return client.post<User>(`/register`, payload)
}

export const getLoggedUser = async () => {
  const res = await client.get<User>(`/user`)
  return res.data
}

function useAuthManager() {
  const location = useLocation()
  const navigate = useNavigate()
  const { cache } = useSWRConfig()

  const url = '/login'
  const key: SWRFetcherKey = url

  const { data } = useSWRImmutable(key, () => AuthToken.get(), {
    suspense: true
  })

  const { trigger: login, isMutating: isLoggingIn } = useSWRMutation<
    LoginResponse,
    any,
    string,
    LoginRequestParams
  >(key, async (_, { arg }) => {
    const { data } = await client.post<LoginResponse>(url, arg)

    if (isCacheClearable(cache)) {
      cache.clear()
    }

    AuthToken.set(data)

    return data
  })

  const { trigger: logout, isMutating: isLoggingOut } = useSWRMutation<
    void,
    any,
    typeof key,
    void
  >(key, async () => {
    if (isCacheClearable(cache)) {
      cache.clear()
    }

    localStorage.clear()
    AuthToken.clear()

    // We don't want to navigate multiple times, if already on the login page.
    if (!location.pathname.includes('/login')) {
      navigate('/login')

      // Jest does not support navigation in JSDOM.
      if (process.env.NODE_ENV !== 'test') {
        window.location.reload()
      }
    }
  })

  const { trigger: verifyAuthToken, isMutating: isVerifyingToken } =
    useSWRMutation<void, any, typeof key, string>(key, async (_, { arg }) => {
      const token = AuthToken.get()

      if (!token) {
        return
      }

      await verifyToken(arg)
      AuthToken.set({ ...token, requiresTwoFactorAuthentication: false })
    })

  const { token = null, requiresTwoFactorAuthentication = null } = data || {}

  return {
    isVerifyingToken,
    isLoggingIn,
    isLoggingOut,
    token,
    requiresTwoFactorAuthentication,
    login: (
      params: LoginRequestParams,
      options?: Parameters<typeof login>[1]
    ) => login(params, options),
    logout: (options?: Parameters<typeof logout>[1]) =>
      logout(undefined, options),
    verifyToken: (
      code: string,
      options?: Parameters<typeof verifyAuthToken>[1]
    ) => verifyAuthToken(code, options),
    isAuthenticated: Boolean(token && !requiresTwoFactorAuthentication)
  }
}

export function useAuth() {
  const auth = useAuthManager()

  const url = `/user`
  const key: SWRFetcherKey = url

  const { data: user } = useSWRImmutable<User | null>(
    auth.isAuthenticated ? key : null,
    {
      suspense: true,
      onError: async err => {
        if (
          axios.isAxiosError(err) &&
          err.response?.status === HttpStatus.Unauthorized
        ) {
          await auth.logout()
        }
      }
    }
  )

  const { trigger: update } = useSWRMutation<
    AxiosResponse<void>,
    any,
    typeof key,
    UpdateCurrentUserParams
  >(key, (_, { arg }) => client.put<void>(url, arg))

  return {
    ...auth,
    isLoggedIn: Boolean(
      auth.isAuthenticated && user && Object.keys(user).length > 0
    ),
    get maybeUser(): User | null {
      // The value returned by the server will either be `null` or
      // an empty object when there is no user currently logged in.
      if (user && Object.keys(user).length > 0) {
        // User endpoint does not return actual userId and we thus have to
        // manually parse it and assign it out of the auth token.
        return user
      }

      return null
    },
    get user(): User {
      if (this.maybeUser === null) {
        throw new Error(
          'No logged in user; there is either a bug in the application logic or you may want to explicitly check whether user is logged.'
        )
      }

      return this.maybeUser
    },
    /**
     * Update the currently authenticated user's data.
     */
    update: async (
      params: UpdateCurrentUserParams,
      options?: Parameters<typeof update>[1]
    ) => {
      return update(params, options)
    }
  }
}

function isCacheClearable(cache: any): cache is { clear: () => void } {
  if (typeof cache === 'object' && typeof cache.clear === 'function') {
    return true
  }

  if (process.env.NODE_ENV !== 'production') {
    throw new Error(
      'SWR cache does not have a clear method! This bears a possible risk of data leak between users.'
    )
  }

  return false
}

export const AuthToken = {
  get: () => {
    const auth = localStorage.getItem('__auth__')
    return auth ? (JSON.parse(auth) as LoginResponse) : null
  },
  set: (value: LoginResponse) =>
    localStorage.setItem('__auth__', JSON.stringify(value)),
  clear: () => localStorage.removeItem('__auth__')
}
