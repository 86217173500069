export type CurrencyFormatOptions = Pick<Intl.NumberFormatOptions, 'currency'> &
  Partial<{
    maxDecimals?: number
  }>

export const formatCurrency = (
  val: string | number = 0,
  { maxDecimals = 2, currency = 'EUR' }: CurrencyFormatOptions = {}
) => {
  if (typeof val === 'string') {
    val = parseFloat(val)
  }

  return `${new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
    maximumFractionDigits: maxDecimals
  }).format(val)}`
}

export const isCurrency = (val: string) => {
  const currencyRows = [
    'FTD TTL Deposits',
    'Deposits',
    'Withdrawals',
    'GGR',
    'NGR',
    'RS% Cost',
    'RS% Cost',
    'CPA Cost',
    'TTL Cost',
    'D-PP',
    'W-PP',
    'NGR-PP',
    'C-PP',
    'RAW-RPP',
    'P-PP',
    'Total Deposits Amount',
    'CPA Profit',
    'RS% Profit',
    'TTL Profit',
    'Master Profit'
  ]

  return currencyRows.includes(val)
}
