import { DataTableColumns } from 'shared/components/organisms/data-table'

export type ExportColumns = Record<string, string>

/**
 * Transform data table columns to valid export columns.
 */
export function toExportColumns(cols: DataTableColumns<any>): ExportColumns {
  const exp: Record<string, string> = {}

  for (const col of cols) {
    exp[col.field] = col.headerName || col.field
  }

  return exp
}
