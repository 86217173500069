import { Navigate, Route, Routes } from 'react-router-dom'
import { PublicRoutes } from 'config/routes/routes'
import { makeRoutes } from 'config/routes/support'
import Screens from 'shared/screens/Screens'

export const GuestRoutes = () => {
  return (
    <Routes>
      {makeRoutes(PublicRoutes)}
      <Route path="/" element={<Navigate to="login" replace />} />
      <Route path="/login" element={<Screens.Login />} />
      <Route path="/register" element={<Screens.Register />} />
      <Route path="*" element={<Screens.NotFoundPage />} />
    </Routes>
  )
}
